<template>
  <div class="columnistItem-box">
    <div class="columnist-left__box">
      <div class="left-title">
        {{info.title}}
      </div>
      <div class="left-bottom">
        <span>{{info.publishTime}}</span>
        <span>时长 {{info.audioDuration}}</span>
      </div>
    </div>
    <img  class="right-arrow" src="@/assets/imgs/columnist/play.png">
<!--    <img v-if="info.columnistType=='audio'" class="right-arrow" src="@/assets/imgs/album/play.png">-->
<!--    <img v-else-if="info.columnistType=='text'" class="right-arrow" src="@/assets/imgs/album/more.png">-->
    
  </div>
</template>

<script>
  export default {
    props: {
      info: Object
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>
.columnistItem-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 12px 16px;
}

.columnist-left__box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
}

.left-title {
  width: 250px;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  color: #333333;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

.left-bottom {
  display: flex;
}

.left-bottom span {
  margin-right: 8px;
}

.right-arrow {
  width: 26px;
  height: 26px;
}
</style>