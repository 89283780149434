<template>
  <div class="page">
    <van-tabs v-model="activeName" color="#FFC83E" line-width="22px">
      <van-tab title="优选专栏" name="album">
        <van-list
            v-if="!isEmpty"
            :finished="finished">

          <album-item
            v-for="item in albumList"
            :key="item.id"
            :album="item"
            @getList="getFavorityList('album')"
          ></album-item>

        </van-list>
        <empty-tip v-else tips="暂无收藏信息"></empty-tip>
      </van-tab>
      <van-tab title="热门专题" name="topic">
        <van-list
            v-if="!isEmpty"
            :finished="finished">
          <topic-item
            v-for="item in topicList"
            :key="item.id"
            :topic="item"
            @getList="getFavorityList('topic')"></topic-item>
        </van-list>
        <empty-tip v-else tips="暂无收藏信息"></empty-tip>
      </van-tab>
      <van-tab title="文章" name="article">
        <van-list
            v-if="!isEmpty"
            :finished="finished">
          <album-detail-item
              v-for="(item, idx) in articleList"
              :key="idx" :info="item"
              @click.native="$router.push({ name: 'Article', query: {id: item.id}})"></album-detail-item>
        </van-list>
        <empty-tip v-else tips="暂无收藏信息"></empty-tip>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import AlbumItem from "./AlbumItem.vue";
import TopicItem from "./TopicItem.vue";
import AlbumDetailItem from "../album/AlbumDetailItem.vue"
import EmptyTip from '@/components/EmptyTip'
import { getFavorityList } from "@/api/favority";
import { Tab, Tabs, List } from "vant";
import to from "@/utils/to";

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    AlbumItem,
    TopicItem,
    AlbumDetailItem,
    EmptyTip
  },
  data() {
    return {
      albumList: [],
      topicList: [],
      articleList: [],
      activeName: "album",

      isEmpty: false,
      finished: false
    };
  },
  watch: {
    activeName: {
      handler: function (value) {
        this.getFavorityList(value);
      },
      immediate: true,
    },
  },
  methods: {
    async getFavorityList(favType) {
      this.isEmpty = false
      let [err, res] = await to(getFavorityList(favType));
      if (err) {
        return;
      }
      if (res.rows.length === 0) {
        this.finished = true
      }
      switch (favType) {
        case "album":
          this.albumList = res.rows;
          if (this.albumList.length <= 0) {
            this.isEmpty = true
          }
          break;
        case "topic":
          this.topicList = res.rows;
          if (this.topicList.length <= 0) {
            this.isEmpty = true
          }
          break;
        case "article":
          this.articleList = res.rows;
          if (this.articleList.length <= 0) {
            this.isEmpty = true
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-color: #fff;
}

.page >>> .van-tab {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #808080;
  border-radius: 4px;
}

.page >>> .van-tab--active {
  color: #333333;
}
</style>
