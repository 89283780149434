<template>
  <div class="item" >
    <div class="item-box" @click="$router.push({ name: 'Topic', query: { id: topic.id } })">
      <img class="img-box" :src="topic.coverUrl" />

      <div class="item-left__box">
        <div class="left-title">
          {{ topic.name }}
        </div>
        <div class="left-sub__title">
          {{ topic.desc }}
        </div>
        <div class="left-bottom">
          <img class="icon-box" src="@/assets/imgs/favorite/icon.png" />
          已更新{{ topic.articleNum }}期
        </div>
      </div>
      <img class="right-arrow" src="@/assets/imgs/favorite/more.png" @click.stop="onMoreTap(topic)"/>
    </div>
    <van-divider />
    <van-action-sheet
        v-model="showActionSheet"
        :actions="actions"
        @select="onActionSheetSelect"
        @close="onClose"
        cancel-text="取消"
    />
  </div>
</template>

<script>
import { Divider, ActionSheet, Dialog } from "vant";
import { toggleFavorites } from '@/api/mine'
export default {
  props: {
    topic: {
      type: Object,
    },
  },
  components: {
    [Divider.name]: Divider,
    [ActionSheet.name]: ActionSheet,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      curSelectId: '',
      actions: [{
        name: '取消收藏'
      }],
      showActionSheet: false,
    };
  },
  methods: {
    onMoreTap(item) {
      console.log(item)
      this.curSelectId = item.id
      this.showActionSheet = true
      // const isTop = item.isTop
      // if (isTop) {
      //   this.actions = [{name: '取消收藏'}]
      // } else {
      //   this.actions = [{name: '取消收藏'}]
      // }
    },
    onActionSheetSelect(evt) {
      const actionName = evt.name
      if (actionName === '取消收藏') {
        this.deleteData()
      }
    },
    deleteData() {
      Dialog.confirm({
        title: '提示',
        message: '确定删除该收藏记录'
      }).then(() => {
        toggleFavorites(this.curSelectId, 'topic').then(() => {
          this.$emit('getList')
        }).finally(() => {
          this.onClose()
        })
      }).catch(() => {
        this.onClose()
      });
    },
    onClose() {
      this.showActionSheet = false
    },
  },
};
</script>

<style scoped>
.item {
  padding: 8px 16px 0 16px;
}

.item >>> .van-divider {
  margin: 8px 0 0 0;
}

.item-box {
  display: flex;
  align-items: center;
}

.img-box {
  height: 70px;
}

.item-left__box {
  height: 60px;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 8px;
}

.left-title {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #333333;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.left-sub__title {
  width: 200px;
  line-height: 17px;
  color: #808080;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 4px 0 8px 0;
}

.icon-box {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.left-bottom {
  display: flex;
  align-items: center;
  color: #808080;
}

.right-arrow {
  width: 22px;
  height: 22px;
}
</style>